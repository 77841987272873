var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c(
        "a-form",
        {
          ref: "formLogin",
          staticClass: "user-layout-login",
          attrs: { form: _vm.form, id: "formLogin" },
        },
        [
          _c("a-form-item", [
            _c("span", { staticClass: "title" }, [
              _vm._v("欢迎来到漫小崔之家"),
            ]),
          ]),
          _c(
            "a-form-item",
            { staticClass: "form-row" },
            [
              _c(
                "a-input",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: [
                        "username",
                        _vm.validatorRules.username,
                        { validator: this.handleUsernameOrEmail },
                      ],
                      expression:
                        "['username', validatorRules.username, { validator: this.handleUsernameOrEmail }]",
                    },
                  ],
                  staticStyle: { height: "30px !important" },
                  attrs: {
                    size: "large",
                    type: "text",
                    placeholder: "请输入账户",
                  },
                },
                [
                  _c("a-icon", {
                    style: { color: "rgba(0,0,0,.25)" },
                    attrs: { slot: "prefix", type: "user" },
                    slot: "prefix",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-item",
            { staticClass: "form-row" },
            [
              _c(
                "a-input",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: ["password", _vm.validatorRules.password],
                      expression: "['password', validatorRules.password]",
                    },
                  ],
                  staticStyle: { height: "30px !important" },
                  attrs: {
                    size: "large",
                    type: "password",
                    autocomplete: "false",
                    placeholder: "请输入密码",
                  },
                },
                [
                  _c("a-icon", {
                    style: { color: "rgba(0,0,0,.25)" },
                    attrs: { slot: "prefix", type: "lock" },
                    slot: "prefix",
                  }),
                  _c(
                    "a-tooltip",
                    {
                      attrs: {
                        slot: "suffix",
                        overlayClassName: "forget-pass",
                      },
                      slot: "suffix",
                    },
                    [
                      _c("template", { slot: "title" }, [
                        _c(
                          "span",
                          { staticStyle: { "margin-top": "100px !important" } },
                          [_vm._v("请联系系统管理员或统筹")]
                        ),
                      ]),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#d64a6a",
                            "font-size": "12px",
                            "margin-top": "6px",
                          },
                        },
                        [_vm._v("忘记密码？")]
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-tooltip",
            {
              attrs: {
                overlayClassName: "errMsg",
                visible: _vm.errMsg != "",
                placement: "bottom",
              },
            },
            [
              _c("template", { slot: "title" }, [
                _c("span", { staticClass: "longin-tooltip" }, [
                  _vm._v(
                    _vm._s(
                      _vm.errMsg != "该人员不存在，请注册" &&
                        _vm.errMsg != "人员名或密码错误"
                        ? "服务器开小差啦，请稍后再试。"
                        : _vm.errMsg
                    )
                  ),
                ]),
              ]),
              _c(
                "a-row",
                {
                  staticClass: "form-row",
                  staticStyle: { "z-index": "1" },
                  attrs: { gutter: 0 },
                },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-form-item",
                        [
                          _c(
                            "a-button",
                            {
                              staticClass: "login-btn",
                              attrs: { loading: _vm.btnLoadding },
                              on: {
                                click: function ($event) {
                                  _vm.handleSubmit(
                                    () => {},
                                    () => {}
                                  )
                                },
                              },
                            },
                            [_vm._v("登录")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "a-form-item",
            { staticStyle: { display: "none" } },
            [
              _c(
                "a-checkbox",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: [
                        "rememberMe",
                        { initialValue: true, valuePropName: "checked" },
                      ],
                      expression:
                        "['rememberMe', { initialValue: true, valuePropName: 'checked' }]",
                    },
                  ],
                },
                [_vm._v("自动登录")]
              ),
              _c(
                "router-link",
                {
                  staticClass: "forge-password",
                  staticStyle: { float: "right" },
                  attrs: { to: { name: "alteration" } },
                },
                [_vm._v(" 忘记密码 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.requiredTwoStepCaptcha
        ? _c("two-step-captcha", {
            attrs: { visible: _vm.stepCaptchaVisible },
            on: {
              success: _vm.stepCaptchaSuccess,
              cancel: _vm.stepCaptchaCancel,
            },
          })
        : _vm._e(),
      _c("login-select-tenant", {
        ref: "loginSelect",
        on: { success: _vm.loginSelectOk },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }