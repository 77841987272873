var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: { centered: "", maskClosable: false },
      on: { cancel: _vm.handleCancel },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c(
        "div",
        {
          style: { textAlign: "center" },
          attrs: { slot: "title" },
          slot: "title",
        },
        [_vm._v("两步验证")]
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          { style: { textAlign: "center" } },
          [
            _c("a-button", { key: "back", on: { click: _vm.handleCancel } }, [
              _vm._v("返回"),
            ]),
            _c(
              "a-button",
              {
                key: "submit",
                attrs: { type: "primary", loading: _vm.stepLoading },
                on: { click: _vm.handleStepOk },
              },
              [_vm._v("\n        继续\n      ")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "a-spin",
        { attrs: { spinning: _vm.stepLoading } },
        [
          _c(
            "a-form",
            {
              attrs: {
                layout: "vertical",
                "auto-form-create": (form) => {
                  this.form = form
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "step-form-wrapper" },
                [
                  !_vm.stepLoading
                    ? _c("p", { staticStyle: { "text-align": "center" } }, [
                        _vm._v(
                          "请在手机中打开 Google Authenticator 或两步验证 APP"
                        ),
                        _c("br"),
                        _vm._v("输入 6 位动态码"),
                      ])
                    : _c("p", { staticStyle: { "text-align": "center" } }, [
                        _vm._v("正在验证.."),
                        _c("br"),
                        _vm._v("请稍后"),
                      ]),
                  _c(
                    "a-form-item",
                    {
                      style: { textAlign: "center" },
                      attrs: {
                        hasFeedback: "",
                        fieldDecoratorId: "stepCode",
                        fieldDecoratorOptions: {
                          rules: [
                            {
                              required: true,
                              message: "请输入 6 位动态码!",
                              pattern: /^\d{6}$/,
                              len: 6,
                            },
                          ],
                        },
                      },
                    },
                    [
                      _c("a-input", {
                        style: { textAlign: "center" },
                        attrs: { placeholder: "000000" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.handleStepOk.apply(null, arguments)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c("p", { staticStyle: { "text-align": "center" } }, [
                    _c("a", { on: { click: _vm.onForgeStepCode } }, [
                      _vm._v("遗失手机?"),
                    ]),
                  ]),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }