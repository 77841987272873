<template>
  <div class="main">
    <a-form :form="form" class="user-layout-login" ref="formLogin" id="formLogin">
      <a-form-item>
        <span class="title">欢迎来到漫小崔之家</span>
      </a-form-item>

      <a-form-item class="form-row">
        <a-input
          style="height:30px !important;"
          size="large"
          v-decorator="['username', validatorRules.username, { validator: this.handleUsernameOrEmail }]"
          type="text"
          placeholder="请输入账户"
        >
          <a-icon slot="prefix" type="user" :style="{ color: 'rgba(0,0,0,.25)' }" />
        </a-input>
      </a-form-item>

      <a-form-item class="form-row">
        <a-input
          style="height:30px !important;"
          v-decorator="['password', validatorRules.password]"
          size="large"
          type="password"
          autocomplete="false"
          placeholder="请输入密码"
        >
          <a-icon slot="prefix" type="lock" :style="{ color: 'rgba(0,0,0,.25)' }" />
          <a-tooltip slot="suffix" overlayClassName='forget-pass'>
            <template slot='title'>
              <span  style="margin-top:100px !important;">请联系系统管理员或统筹</span>
            </template>
            <span style="color:#d64a6a;font-size:12px;margin-top: 6px;">忘记密码？</span>
          </a-tooltip>
        </a-input>
      </a-form-item>

      <a-tooltip overlayClassName="errMsg" :visible="errMsg != ''" placement="bottom">
        <template slot="title">
          <span class="longin-tooltip">{{
            errMsg != '该人员不存在，请注册' && errMsg != '人员名或密码错误' ? '服务器开小差啦，请稍后再试。' : errMsg
          }}</span>
        </template>
        <a-row :gutter="0" class="form-row" style="z-index:1">
          <a-col :span="24">
            <a-form-item>
              <!-- <a-input
              v-decorator="['inputCode', validatorRules.inputCode]"
              size="large"
              type="text"
              @change="inputCodeChange"
              placeholder="验证码"
            >
              <a-icon slot="prefix" type="smile" :style="{ color: 'rgba(0,0,0,.25)' }" />
            </a-input> -->
              <!-- <verify
              v-if="captchaVerification == ''"
              @success="verifySuccess"
              :mode="'fixed'"
              :captchaType="'blockPuzzle'"
              :imgSize="{ width: '240px', height: '155px' }"
              ref="verify"
              ></verify>
              <label
              v-if="captchaVerification != ''"
              style="width: 100%; color: green"
              >验证成功</label> -->
              <!-- <div class="verify">
                <div id="verify_box">
                  <div id="verify_xbox">
                    <div id="btn">
                      <img src="/arrow.png">
                    </div>
                  </div>
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;按住滑块，拖动到最右边
                </div>
              </div> -->
              <a-button
                class="login-btn"
                :loading='btnLoadding'
                @click="
                  handleSubmit(
                    () => {},
                    () => {}
                  )
                "
                >登录</a-button
              >
              <!-- <a-button style="width: 100%" @click="useVerify">点击完成验证</a-button> -->
            </a-form-item>
          </a-col>
          <!-- <a-col :span="8" style="text-align: right">
            <img v-if="requestCodeSuccess" style="margin-top: 2px" :src="randCodeImage" @click="handleChangeCheckCode" />
            <img v-else style="margin-top: 2px" src="../../assets/checkcode.png" @click="handleChangeCheckCode" />
          </a-col> -->
        </a-row>
      </a-tooltip>

      <!-- <a-tab-pane key="tab2" tab="手机号登录">
          <a-form-item>
            <a-input
              v-decorator="['mobile',validatorRules.mobile]"
              size="large"
              type="text"
              placeholder="手机号">
              <a-icon slot="prefix" type="mobile" :style="{ color: 'rgba(0,0,0,.25)' }"/>
            </a-input>
          </a-form-item>

          <a-row :gutter="16">
            <a-col class="gutter-row" :span="16">
              <a-form-item>
                <a-input
                  v-decorator="['captcha',validatorRules.captcha]"
                  size="large"
                  type="text"
                  placeholder="请输入验证码">
                  <a-icon slot="prefix" type="mail" :style="{ color: 'rgba(0,0,0,.25)' }"/>
                </a-input>
              </a-form-item>
            </a-col>
            <a-col class="gutter-row" :span="8">
              <a-button
                class="getCaptcha"
                tabindex="-1"
                :disabled="state.smsSendBtn"
                @click.stop.prevent="getCaptcha"
                v-text="!state.smsSendBtn && '获取验证码' || (state.time+' s')"></a-button>
            </a-col>
          </a-row>
        </a-tab-pane> -->

      <a-form-item style="display: none">
        <a-checkbox v-decorator="['rememberMe', { initialValue: true, valuePropName: 'checked' }]">自动登录</a-checkbox>
        <router-link :to="{ name: 'alteration' }" class="forge-password" style="float: right"> 忘记密码 </router-link>
        <!-- <router-link :to="{ name: 'register'}" class="forge-password" style="float: right;margin-right: 10px" >
          注册账户
        </router-link> -->
      </a-form-item>

      <!-- <a-form-item style="margin-bottom: 0px">
        <a-button
          size="large"
          type="primary"
          htmlType="submit"
          class="login-button"
          :loading="loginBtn"
          @click.stop.prevent="handleSubmit"
          :disabled="loginBtn"
        >登录
        </a-button>
      </a-form-item> -->
    </a-form>

    <two-step-captcha
      v-if="requiredTwoStepCaptcha"
      :visible="stepCaptchaVisible"
      @success="stepCaptchaSuccess"
      @cancel="stepCaptchaCancel"
    ></two-step-captcha>
    <login-select-tenant ref="loginSelect" @success="loginSelectOk"></login-select-tenant>
    <!-- <third-login ref="thirdLogin"></third-login> -->
  </div>
</template>

<script>
//import md5 from "md5"
import api from '@/api'
import TwoStepCaptcha from '@/components/tools/TwoStepCaptcha'
import { mapActions } from 'vuex'
import { timeFix } from '@/utils/util'
import Vue from 'vue'
import { ACCESS_TOKEN, ENCRYPTED_STRING } from '@/store/mutation-types'
import { putAction, postAction, getAction } from '@/api/manage'
import { encryption, getEncryptedString } from '@/utils/encryption/aesEncrypt'
import store from '@/store/'
import { USER_INFO } from '@/store/mutation-types'
import ThirdLogin from './third/ThirdLogin'
import LoginSelectTenant from './LoginSelectTenant'
import Verify from '../../components/verifition/Verify.vue'
import { checkInspectAccount } from '@/utils/util'
import { simpleDebounce } from '@/utils/util'
export default {
  components: {
    LoginSelectTenant,
    TwoStepCaptcha,
    ThirdLogin,
    Verify
  },
  data() {
    return {
      customActiveKey: 'tab1',
      loginBtn: false,
      // login type: 0 email, 1 username, 2 telephone
      loginType: 0,
      requiredTwoStepCaptcha: false,
      stepCaptchaVisible: false,
      form: this.$form.createForm(this),
      encryptedString: {
        key: '',
        iv: ''
      },
      state: {
        time: 60,
        smsSendBtn: false
      },
      validatorRules: {
        username: { rules: [{ required: true, message: '' }, { validator: this.handleUsernameOrEmail }] },
        password: { rules: [{ required: true, message: '', validator: 'click' }] },
        mobile: { rules: [{ validator: this.validateMobile }] },
        captcha: { rule: [{ required: true, message: '请输入验证码!' }] },
        inputCode: { rules: [{ required: true, message: '请输入验证码!' }] }
      },
      verifiedCode: '',
      inputCodeContent: '',
      inputCodeNull: true,
      currentUsername: '',
      currdatetime: '',
      randCodeImage: '',
      requestCodeSuccess: false,
      captchaVerification: '',
      errMsg: '',
      btnLoadding:false,
    }
  },
  created() {
    this.currdatetime = new Date().getTime()
    Vue.ls.remove(ACCESS_TOKEN)
    Vue.ls.remove(USER_INFO)
    this.getRouterData()
    /* this.handleChangeCheckCode() */
    // update-begin- --- author:scott ------ date:20190805 ---- for:密码加密逻辑暂时注释掉，有点问题
    //this.getEncrypte();
    // update-end- --- author:scott ------ date:20190805 ---- for:密码加密逻辑暂时注释掉，有点问题
  },
  mounted() {
    /* this.captcha() */
  },
  methods: {
    ...mapActions(['Login', 'Logout', 'PhoneLogin']),
    captcha() {
      let that = this
      var box = document.getElementById('verify_box')
      var xbox = document.getElementById('verify_xbox')
      var element = document.getElementById('btn')
      var b = box.offsetWidth
      var o = element.offsetWidth
      element.ondragstart = function() {
        return false
      }
      element.onselectstart = function() {
        return false
      }
      element.ontouchstart = element.onmousedown = e => {
        this.errMsg = ''
        var disX = (e.clientX || e.changedTouches[0].clientX) - element.offsetLeft
        document.ontouchmove = document.onmousemove = function(e) {
          console.log('ontouchmove', e)
          var l = (e.clientX || e.changedTouches[0].clientX) - disX + o
          if (l < o) {
            l = o
          }
          if (l > b) {
            l = b
          }
          xbox.style.width = l + 'px'
        }
        document.ontouchend = document.onmouseup = function(e) {
          console.log('ontouchend', e)
          var l = (e.clientX || e.changedTouches[0].clientX) - disX + o
          if (l < b) {
            l = o
            xbox.style.width = l + 'px'
            document.ontouchmove = document.onmousemove = null
            document.ontouchend = document.onmouseup = null
          } else {
            document.ontouchmove = document.onmousemove = null
            document.ontouchend = document.onmouseup = null
            that.handleSubmit(
              // 成功的逻辑
              () => {
                xbox.innerHTML = '验证通过'
                l = b
                document.ontouchmove = document.onmousemove = null
                document.ontouchend = document.onmouseup = null
              },
              // 失败的逻辑
              () => {
                l = o
                xbox.style.width = l + 'px'
                document.ontouchmove = document.onmousemove = null
                document.ontouchend = document.onmouseup = null
              }
            )
            // xbox.innerHTML = '验证通过<div id="btn"><img style="margin-top:8px" src="/success.png"/></div>';
          }
        }
      }
    },
    // handler
    handleUsernameOrEmail(rule, value, callback) {
      const regex = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/
      if (regex.test(value)) {
        this.loginType = 0
      } else {
        this.loginType = 1
      }
      callback()
    },
    handleTabClick(key) {
      this.customActiveKey = key
      // this.form.resetFields()
    },
    handleSubmit:simpleDebounce(function (callback,errHandle) {
      this.handleSubmitSim(callback,errHandle)
    },500),
    handleSubmitSim(callback, errHandle) {
      // Vue.ls.remove(USER_INFO)
      /*       if (!this.captchaVerification) {
        this.useVerify()
        return
      } */
      let that = this
      let loginParams = {}
      that.loginBtn = true
      that.btnLoadding=true
      // 使用账户密码登录
      if (that.customActiveKey === 'tab1') {
        that.form.validateFields(
          ['username', 'password', 'inputCode', 'rememberMe'],
          { force: true },
          (err, values) => {
            if (!err) {
              loginParams.username = values.username
              // update-begin- --- author:scott ------ date:20190805 ---- for:密码加密逻辑暂时注释掉，有点问题
              //loginParams.password = md5(values.password)
              //loginParams.password = encryption(values.password,that.encryptedString.key,that.encryptedString.iv)
              loginParams.password = values.password
              loginParams.remember_me = values.rememberMe
              // update-begin- --- author:scott ------ date:20190805 ---- for:密码加密逻辑暂时注释掉，有点问题
              loginParams.captcha = that.inputCodeContent
              loginParams.captchaVerification = that.captchaVerification
              loginParams.checkKey = that.currdatetime
              console.log('登录参数', loginParams)
              that
                .Login(loginParams)
                .then(res => {
                  callback?.()
                  that.btnLoadding=false
                  this.loginSuccess(res.data)
                  // this.$refs.loginSelect.show(res.data)
                })
                .catch(err => {
                  that.btnLoadding=false
                  console.log({ err })
                  errHandle?.()
                  that.captchaVerification = ''
                  that.requestFailed(err)
                })
            } else {
              that.btnLoadding=false
              that.loginBtn = false
              errHandle?.()
            }
          }
        )
        // 使用手机号登录
      } else {
        that.form.validateFields(['mobile', 'captcha', 'rememberMe'], { force: true }, (err, values) => {
          if (!err) {
            loginParams.mobile = values.mobile
            loginParams.captcha = values.captcha
            loginParams.remember_me = values.rememberMe
            that
              .PhoneLogin(loginParams)
              .then(res => {
                console.log(res.data)
                that.btnLoadding=false
                this.$refs.loginSelect.show(res.data)
              })
              .catch(err => {
                that.btnLoadding=false
                that.requestFailed(err)
              })
          }
        })
      }
    },
    getCaptcha(e) {
      e.preventDefault()
      let that = this
      this.form.validateFields(['mobile'], { force: true }, (err, values) => {
        if (!values.mobile) {
          that.cmsFailed('请输入手机号')
        } else if (!err) {
          this.state.smsSendBtn = true
          let interval = window.setInterval(() => {
            if (that.state.time-- <= 0) {
              that.state.time = 60
              that.state.smsSendBtn = false
              window.clearInterval(interval)
            }
          }, 1000)

          const hide = this.$message.loading('验证码发送中..', 0)
          let smsParams = {}
          smsParams.mobile = values.mobile
          smsParams.smsmode = '0'
          postAction('/sys/sms', smsParams)
            .then(res => {
              if (!res.success) {
                setTimeout(hide, 0)
                this.cmsFailed(res.message)
              }
              console.log(res)
              setTimeout(hide, 500)
            })
            .catch(err => {
              setTimeout(hide, 1)
              clearInterval(interval)
              that.state.time = 60
              that.state.smsSendBtn = false
              this.requestFailed(err)
            })
        }
      })
    },
    stepCaptchaSuccess() {
      this.loginSuccess()
    },
    stepCaptchaCancel() {
      this.Logout().then(() => {
        this.loginBtn = false
        this.stepCaptchaVisible = false
      })
    },
    handleChangeCheckCode() {
      this.currdatetime = new Date().getTime()
      getAction(`/sys/randomImage/${this.currdatetime}`)
        .then(res => {
          if (res.success) {
            this.randCodeImage = res.data
            this.requestCodeSuccess = true
          } else {
            this.$message.error(res.message)
            this.requestCodeSuccess = false
          }
        })
        .catch(() => {
          this.requestCodeSuccess = false
        })
    },
    loginSuccess(data) {
      let query = {}
      if (data.userInfo.firstLogin) {
        query = {
          fromPage: 'login',
          userInfo: encodeURIComponent(JSON.stringify(data.userInfo))
        }
      }
      console.log(data,'loginData')
      localStorage.setItem('websocket_token', data.websocketToken)
      const userInfo = Vue.ls.get(USER_INFO)
      checkInspectAccount(userInfo)
      let redirect = Object.fromEntries(new URLSearchParams(window.location.search.slice(1)))?.redirect
      if (!redirect || redirect == '/') {
        redirect = '/dashboard/analysis'
      }
      // 跳转到上次页面
      this.$router.push({ path: redirect, query }).catch(e => {
        console.log(e)
        console.log('登录跳转首页出错,这个错误从哪里来的, 再试一次')
        this.$router.push({ path: redirect, query }).catch(e => {
          console.log(e)
          console.log('登录跳转首页出错,这个错误从哪里来的')
        })
      })
      this.$notification.success({
        message: '欢迎',
        description: `${timeFix()}，欢迎回来`
      })
    },
    cmsFailed(err) {
      this.$notification['error']({
        message: '登录失败',
        description: err,
        duration: 4
      })
      this.loginBtn = false
    },
    requestFailed(err) {
      console.log('requestFailed')
      /* this.$notification['error']({
        message: '登录失败',
        description: ((err.response || {}).data || {}).message || err.message || '请求出现错误，请稍后再试',
        duration: 4,
      }) */
      this.errMsg = ((err.response || {}).data || {}).message || err.message || '请求出现错误，请稍后再试'
      this.loginBtn = false
      setTimeout(() => (this.errMsg = ''), 2000)
    },
    validateMobile(rule, value, callback) {
      if (!value || new RegExp(/^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/).test(value)) {
        callback()
      } else {
        callback('您的手机号码格式不正确!')
      }
    },
    validateInputCode(rule, value, callback) {
      if (!value || this.verifiedCode == this.inputCodeContent) {
        callback()
      } else {
        callback('您输入的验证码不正确!')
      }
    },
    generateCode(value) {
      this.verifiedCode = value.toLowerCase()
    },
    inputCodeChange(e) {
      this.inputCodeContent = e.target.value
    },
    loginSelectOk() {
      this.loginSuccess()
    },
    getRouterData() {
      this.$nextTick(() => {
        if (this.$route.params.username) {
          this.form.setFieldsValue({
            username: this.$route.params.username
          })
        }
      })
    },
    //获取密码加密规则
    getEncrypte() {
      var encryptedString = Vue.ls.get(ENCRYPTED_STRING)
      if (encryptedString == null) {
        getEncryptedString().then(data => {
          this.encryptedString = data
        })
      } else {
        this.encryptedString = encryptedString
      }
    },
    verifySuccess(params) {
      this.captchaVerification = params.captchaVerification
      this.handleSubmit()
      // params 返回的二次验证参数, 和登录参数一起回传给登录接口，方便后台进行二次验证
    },
    useVerify() {
      /* this.$refs.verify.show() */
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-input {
  height: 35px !important;
  font-size: 12px;
}
/deep/ .login-btn:hover{
  background-color: #ff8f9e;
  color:white;
}
/deep/ .ant-btn {
  height: 30px !important;
  font-size: 12px;
  margin-top: 14px;
}
/deep/ .ant-input::placeholder {
  font-size: 12px;
}
/deep/ .ant-input-affix-wrapper .ant-input-prefix {
  top: 60%;
}
.verify {
  width: 280px;
  height: 40px;
  margin-top: 30px;
}
.login-btn {
  width: 100%;
  background-color: #d64a6a;
  color: #fff;
  border: none;
}
.verify span {
  width: 38px;
  height: 40px;
  font-family: MicrosoftYaHei;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 40px;
  letter-spacing: 0px;
  color: #302f41;
  margin-right: 23px;
}

#verify_box {
  border-radius: 5px;
  width: 240px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  color: #fff;
  position: relative;
  background-color: rgba(212, 212, 212, 1);
}

#verify_xbox {
  width: 54px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  color: #fff;
  position: absolute;
  background: #5389df;
  border-radius: 5px;
}

#btn {
  user-select: none;
  //火狐浏览器
  -moz-user-select: none;
  //谷歌浏览器
  -webkit-user-select: none;
  //IE
  -ms-user-select: none;
  cursor: all-scroll;
  width: 54px;
  height: 40px;
  background-color: rgba(255, 255, 255, 1);
  border: solid 1px rgba(212, 212, 212, 1);
  float: right;
  border-radius: 5px;
  box-shadow: 0 10px 20px -10px rgba(26, 92, 255, 0.5);

  img {
    position: relative;
    bottom: 3px;
  }
}

.continue {
  width: 120px;
  height: 40px;
  background-color: rgba(212, 212, 212, 1);
  margin: 0 70px;
  border: none;
  color: #302f41;
  font-size: 16px;
}

.continue:hover {
  background-color: rgba(235, 54, 38, 1);
  color: #ffffff;
}

img {
  max-width: 100%;
}

.main {
  width: 310px;
  padding: 30px 35px;
  position: relative;
  /*  transform: scale(2); */
  zoom: 1.2;
  /* margin-top: -10%; */
  margin: 0 auto;
  background: rgba(0, 0, 0, 0) !important;
  border-radius: 5px;

  @media (max-width: 768px) {
    margin-right: 0;
  }
}

.bgw {
  background: #ffffff;
  border-radius: 5px;
}

.user-layout-login {
  label {
    font-size: 14px;
  }

  .title {
    font-size: 24px;
    /* font-weight: 700; */
    line-height: 18px;
    z-index: 1;
    color: #fff;
    text-align: center;
    display: block;
    position: relative;
    top: -4px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
  }

  .form-row {
    margin-bottom: 10px;
  }

  .getCaptcha {
    display: block;
    width: 100%;
    height: 40px;
  }

  .forge-password {
    font-size: 14px;
  }

  button.login-button {
    padding: 0 15px;
    font-size: 16px;
    height: 40px;
    width: 100%;
  }

  .user-login-other {
    text-align: left;
    margin-top: 24px;
    line-height: 22px;

    .item-icon {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.2);
      margin-left: 16px;
      vertical-align: middle;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: #1890ff;
      }
    }

    .register {
      float: right;
    }
  }
}
/deep/ .ant-form-explain {
  display: none !important;
}
</style>
<style>
.valid-error .ant-select-selection__placeholder {
  color: #f5222d;
}

.errMsg {
  top: 480px !important;
}
.forget-pass{
  top:420px !important;
  left:52% !important
}
</style>
